@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "GIP-Regular";
}

.resume-section {
  transition: opacity 0.5s ease-in-out;
}

.fade-out {
  opacity: 0;
}

.fade-outs {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.transition-enter {
  opacity: 0;
}

.transition-enter-active {
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}

.transition-exit {
  opacity: 1;
}

.transition-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

@keyframes fadeAndSlideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-80px);
  }
}

.fade-and-slide-out {
  animation: fadeAndSlideOut 0.5s forwards;
}

@keyframes wave {
  25% {
    transform: rotate(20deg);
  }

  75% {
    transform: rotate(-20deg);
  }
}

.waving-hand {
  animation: wave 3s infinite;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100px);
    /* Adjust the value as needed */
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(100px);
    /* Adjust the value as needed */
  }
}

.animate__fadeOutUp {
  animation: fadeOutUp 1s ease forwards;
}

.animate__fadeOutDown {
  animation: fadeOutDown 1s ease forwards;
}

.carousel-container {
  overflow: hidden;
  width: 100%;
}

.carousel {
  display: flex;
  animation: scroll 10s infinite linear;
  /* Adjust speed as needed */
  gap: 2rem;
}

.carousel img {
  margin-right: 2px;
  /* Adjust spacing between images */
}

@keyframes scroll {
  0% {
    transform: translateX(80);
  }

  100% {
    transform: translateX(-100%);
  }
}

.unity-canvas {
  top: 0px;
  /* left: 100px; */
  width: 1152px;
  height: 576px;
  z-index: 1;
}

@font-face {
  font-family: "GIP-Regular";
  src: url("./fonts/GIP-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GIP-Bold";
  src: url("./fonts/GIP-ExtraBoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GIP-Italic";
  src: url("./fonts/GIP-RegularItalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn ease-in 2;
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px; /* Customize width */
  height: 20px; /* Customize height */
  border: 1px solid #666874;
  border-radius: 50%; /* Make it circular */
  cursor: pointer;
  background: radial-gradient(
    circle,
    #55d6a0 0%,
    #55d6a0 60%,
    white 40%,
    white 100%
  );
}
